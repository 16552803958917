<!--<c-footer>-->
<div>
  <a
    href="hhttps://github.com/aws-samples/aws-saas-factory-eks-reference-architecture"
    target="_blank"
    >EKS SaaS Reference Architecture</a
  >
  <span> &copy; 2024 AWS</span>
</div>
<div class="ms-auto">
  Powered by
  <a href="https://aws.amazon.com/saas" target="_blank">
    <span>AWS SaaS Factory</span>
  </a>
</div>
<!--</c-footer>-->
